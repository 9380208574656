import React, {useEffect, useRef, useState} from 'react';
import {Field, Form} from "react-final-form";
import {Button} from "../../components/Buttons";
import {faComment, faPaperclip} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import axios from "axios";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {File} from "../../components/File";
import {ReviewModal} from "../../components/RevierModal";

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 140px;
  right: 0;
  height: 40px;
  background-color: #192B38;
  padding-left: 10px;
  padding-right: 30px;
  color: ${({theme: {colors: {white}}}) => white};
  border: none;
  font-size: 20px;

  span {
    padding-left: 5px;
  }
`;

const StyledBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  top: 180px;
  right: 0;
  height: 600px;
  padding: 10px 55px 10px 10px;
  background-color: ${({theme: {colors: {white}}}) => white};
  border: none;
  opacity: ${({isOpen}) => isOpen ? 1 : 0};
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  transition: transform .3s ease-in-out, opacity .3s ease-in-out;
  transform: translateX(${({isOpen}) => isOpen ? 0 : "500px"});
  width: 500px;
`;

const StyledTextarea = styled.textarea`
  resize: none;
  width: 100%;
  border-radius: 20px;
  padding: 10px;
`;

const CommentButtons = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 5px;
`;

const StyledMessagesList = styled.ul`
  list-style: none;
  overflow-y: scroll;
  margin: 10px 0;
  padding: 0 10px;

  li {
    padding: 10px 10px 15px;
    display: block;
    border-bottom: 1px solid #F0F0F0;
    font-size: 1.4rem;
  }

  div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .author {
      font-weight: bold;
    }

    .date {
      font-style: italic;
    }
  }


`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  div.attachment {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
`;

const StyledForm = styled.form`
  flex-shrink: 0;
`;

const StyledA = styled.a`
  color: black;
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;
`;

export const Comment = ({toDoId, toDoTab, vehicleId, policyId, archived}) => {
    const [modal, setModal] = useState(false);
    const [fileUrl, setFileUrl] = useState(false);
    const [openPanel, setOpenPanel] = useState(false);
    const [comments, setComments] = useState([]);
    const [commentFile, setCommentFile] = useState(null);
    const messagesEndRef = useRef(null)
    const token = useSelector((store) => store.token);

    const scrollToBottom = () => {
        // eslint-disable-next-line no-unused-expressions
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"})
    }

    useEffect(() => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/comments`, {
                params: {
                    toDoId: toDoId,
                    toDoTab: toDoTab,
                    policyId: policyId,
                    vehicleId: vehicleId
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(({data: {comments: newMessages}}) => {
                setComments(newMessages);
            }).finally(() => {
                scrollToBottom();
            });
        }

    }, [token, toDoId, archived]);


    return (
        <>
            <StyledBox isOpen={openPanel}>
                <StyledMessagesList>
                    {comments && comments.map(({userName, content, file, created_at}) => (
                        <li>
                            <div>
                                <div class="author">{userName}</div>
                                <div class="date">{created_at}</div>
                            </div>
                            <MessageWrapper>
                                <span>{content}</span>

                                {file && (
                                    <div class="attachment">
                                        <StyledA onClick={(e) => {
                                            e.preventDefault();
                                            setModal(true);
                                            setFileUrl(file);
                                        }}
                                                 rel="noopener noreferrer"
                                                 target="_blank">
                                            <FontAwesomeIcon icon={faPaperclip} color="grey"
                                                             size="1x"/>
                                        </StyledA>
                                    </div>
                                )}
                            </MessageWrapper>
                        </li>
                    ))}
                    <div ref={messagesEndRef}/>
                </StyledMessagesList>
                <Form
                    onSubmit={(values, form) => {
                        const formData = new FormData();
                        Object.keys(values).forEach((key) => {
                            formData.append(key, values[key] ?? '')
                        });
                        if (commentFile) {
                            formData.append('file', commentFile);
                        }

                        if (toDoId) {
                            formData.append('to_do_id', toDoId);
                        }

                        if (vehicleId) {
                            formData.append('vehicle_id', vehicleId);
                        }

                        if (policyId) {
                            formData.append('policy_id', policyId);
                        }

                        if (toDoTab) {
                            formData.append('toDoTab', toDoTab);
                        }
                        axios.post(`${apiUrl}/comments`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }).then(({data: {comments: newMessages}}) => {
                            setComments(newMessages);
                            scrollToBottom();
                            setCommentFile(null);
                            form.reset();
                        }).catch((e) => {
                            if (e.response?.status === 422) {
                                toast.error("Wiadomość musi mieć treść");
                            } else {
                                toast.error('Coś poszło nie tak.');
                            }
                        })
                    }}
                    render={({handleSubmit}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <Field
                                name='content'
                                render={({input, meta}) => (
                                    <StyledTextarea name="" id="" cols="30" rows="4" {...input}/>
                                )}/>
                            <CommentButtons>
                                <File
                                    name="pathFile"
                                    onFileSelect={(file) => setCommentFile(file)}
                                    initialValue={null} //TODO to check
                                />
                                <Button type="submit" disabled={archived} small smallText>Zapisz</Button>
                            </CommentButtons>
                        </StyledForm>
                    )}
                />
            </StyledBox>
            <StyledButton onClick={() => {
                setOpenPanel(!openPanel)
            }}>
                <FontAwesomeIcon icon={faComment} size="1.5x"></FontAwesomeIcon>
                <span>{comments.length}</span>
            </StyledButton>

            <ReviewModal
                modal={modal}
                fileUrl={fileUrl}
                onClose={() => {
                    setModal(false);
                    setFileUrl(null);
                }}
            ></ReviewModal>
        </>
    );
};
