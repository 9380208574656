import React, {useEffect, useState} from 'react';
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import {Input, Label, RoundSimpleButton, Select} from "../../components/Form";
import {Button} from "../../components/Buttons";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import SmartSelect from 'react-select';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {createFormData} from "../../helpers/form";
import {StyledTextarea} from "../../components/styled/inputs";
import {getDataFromGus} from "../../helpers/gus";
import {File} from "../../components/File";

const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 160px 320px;
    align-items: center;
    margin-bottom: 10px;
`;

const NIPWrapper = styled(InputWrapper)`
    grid-template-columns: 160px 290px 30px;
    button{
    padding: 4px 4px;
    }
`;

const StyledForm = styled.form`
  margin-top: 25px;
  display: flex;
`;

const StyledDivError = styled.div`
      color: red;
      grid-column: 2;
      margin: 5px;
      font-size: 1.2rem;
`;

const StyledSmartSelect = styled(SmartSelect)`
    div:first-child {
        border-radius: 20px;
        border: none;
    }
`;

const RepresentativesContent = styled.div`
  margin-left: 20px;
`;
const RepresentativesWrapper = styled.div`
      display: flex;
      flex-direction: row;
      max-width: 500px;
      flex-wrap: wrap;
`;

const RepresentativeWrapper = styled.div`
  margin-bottom: 15px;
  margin-right: 20px;
`;

export const ClientForm = ({initialValues, onSubmit, disabled, clientId}) => {
    const token = useSelector((store) => store.token);
    const [users, setUsers] = useState([]);
    const [clients, setClients] = useState([]);
    const [krsFile, setKrsFile] = useState(null);
    const [rodoFile, setRodoFile] = useState(null);
    const [marketingFile, setMarketingFile] = useState(null);

    useEffect(() => {
        axios.get(`${apiUrl}/user/all`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {users: newUsers}}) => {
            setUsers(newUsers);
        });
        axios.get(`${apiUrl}/client/all`, {
            params: {
                clientId: clientId !== undefined ? clientId : null
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {clients: newClients}}) => {
            setClients(newClients);
        });
    }, [token, clientId])

    return (
        <Form
            onSubmit={values => {
                const formData = new FormData();
                Object.keys(values).forEach((key) => {
                    formData.append(key, values[key] ?? '')
                })
                createFormData(formData, 'representatives', values.representatives);
                createFormData(formData, 'clients', values.clients);
                if (krsFile) {
                    formData.append('krsFile', krsFile);
                }
                if (rodoFile) {
                    formData.append('rodoFile', rodoFile);
                }
                if (marketingFile) {
                    formData.append('marketingFile', marketingFile);
                }
                onSubmit(formData).finally(() => {
                    setKrsFile(null);
                    setRodoFile(null);
                    setMarketingFile(null);
                });
            }}
            initialValues={initialValues}
            validate={values => {
                const errors = {}
                if (!values.name) {
                    errors.name = 'Nazwa jest wymagana'
                }
                if (!values.leader) {
                    errors.leader = 'Doprowadzający jest wymagany'
                }
                return errors
            }}
            mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, {changeValue}) => {
                    changeValue(state, field, () => value)
                },
                ...arrayMutators
            }}
            render={({handleSubmit, values, form}) => (
                <StyledForm onSubmit={handleSubmit}>
                    <div>
                        <Field
                            name='nip'
                            type="number"
                            parse={value => (value === "" ? null : value)}
                            render={({input, meta}) => (
                                <>
                                    <NIPWrapper>
                                        <Label htmlFor={input.name}>NIP</Label>
                                        <Input {...input} id={input.name} placeholder="NIP" disabled={disabled}/>

                                        {disabled !== true && (
                                            <Button type="button" inactive small onClick={() => {
                                                if (values.nip) {
                                                    getDataFromGus(values.nip, token, (data) => {
                                                        form.mutators.setValue('regon', data.regon)
                                                        form.mutators.setValue('name', data.name)
                                                        form.mutators.setValue('street', `${data.street} ${data.propertyNumber}`)
                                                        form.mutators.setValue('postalCode', data.zipCode)
                                                        form.mutators.setValue('city', data.city)
                                                    });
                                                } else {
                                                    toast.error("Prosze uzupełnij NIP.");
                                                }
                                            }}><FontAwesomeIcon icon={faSearch} color="white"/></Button>
                                        )}
                                    </NIPWrapper>
                                </>
                            )}/>
                        <Field
                            name='activity'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Działalność</Label>
                                        <Select {...input} id={input.name} disabled={disabled}>
                                            <option value="">Działalność</option>
                                            <option value="JDG">JDG</option>
                                            <option value="Sp z o.o.">Sp z o.o.</option>
                                            <option value="Sp z o.o. Sp. K">Sp z o.o. Sp. K</option>
                                            <option value="S.C.">S.C.</option>
                                            <option value="Gospodarstwo rolne">Gospodarstwo rolne</option>
                                            <option value="Rolnik Indywidualny">Rolnik Indywidualny</option>
                                            <option value="Osoba Fizyczna">Osoba Fizyczna</option>
                                            <option value="Inne">Inne</option>
                                        </Select>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='name'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Imię i nazwisko/Firma</Label>
                                        <Input {...input} id={input.name} placeholder="Imię i nazwisko/Firma"
                                               disabled={disabled}/>
                                        {meta.error && meta.touched && <StyledDivError>{meta.error}</StyledDivError>}
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='regon'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Regon</Label>
                                        <Input {...input} id={input.name} placeholder="Regon" disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='krs'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>KRS</Label>
                                        <Input {...input} id={input.name} placeholder="KRS" disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='street'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Ulica i numer lokalu</Label>
                                        <Input {...input} id={input.name} placeholder="Ulica i numer lokalu"
                                               disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='postalCode'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Kod pocztowy</Label>
                                        <Input {...input} id={input.name} placeholder="Kod pocztowy"
                                               disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='city'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Miejscowość</Label>
                                        <Input {...input} id={input.name} placeholder="Miejscowość"
                                               disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='phone'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Numer telefonu</Label>
                                        <Input {...input} id={input.name} placeholder="Numer telefonu"
                                               disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='email'
                            type="email"
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>E-mail</Label>
                                        <Input {...input} id={input.name} placeholder="E-mail" disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='acquiredDate'
                            type="date"
                            parse={value => (value === "" ? null : value)}
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Data pozyskania</Label>
                                        <Input {...input} id={input.name} placeholder="Data pozyskania"
                                               disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='leader'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Doprowadzający</Label>
                                        <Select {...input} id={input.name} disabled={disabled}>
                                            <option value="">Doprowadzający</option>
                                            {users.map((user) => (
                                                <option value={user.name}>{user.name}</option>
                                            ))}
                                        </Select>
                                        {meta.error && meta.touched && <StyledDivError>{meta.error}</StyledDivError>}
                                    </InputWrapper>
                                </>
                            )}/>

                        <Field
                            name='clients'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Powiązani klienci</Label>
                                        <StyledSmartSelect {...input} id={input.name}
                                                           isMulti
                                                           searchable
                                                           isDisabled={disabled}
                                                           placeholder="Powiązani klienci"
                                                           noOptionsMessage={() => 'Brak opcji'}
                                                           options={clients.map(({id, name}) => {
                                                               return {
                                                                   value: id,
                                                                   label: name
                                                               };
                                                           })}
                                        />
                                    </InputWrapper>
                                </>
                            )}
                        />

                        <File
                            name="krs_path"
                            label="CEIDG/KRS"
                            onFileSelect={(file) => setKrsFile(file)}
                            initialValue={values.krsFile}
                        />

                        <File
                            name="rodo_path"
                            label="Wniosek/RODO"
                            onFileSelect={(file) => setRodoFile(file)}
                            initialValue={values.rodoFile}
                        />

                        <File
                            name="marketing_path"
                            label="Zgoda marketingowa"
                            onFileSelect={(file) => setMarketingFile(file)}
                            initialValue={values.marketingFile}
                        />

                        {disabled !== true && (
                            <Button type="submit">Zapisz</Button>
                        )}
                    </div>
                    <RepresentativesContent>
                        <FieldArray name="representatives">
                            {({fields}) => (
                                <div>
                                    <RoundSimpleButton
                                        type="button"
                                        onClick={() => fields.push({})}>
                                        <FontAwesomeIcon icon={faPlus} color="white"/>
                                    </RoundSimpleButton>
                                    <RoundSimpleButton
                                        type="button"
                                        onClick={() => fields.pop()}>
                                        <FontAwesomeIcon icon={faMinus} color="white"/>
                                    </RoundSimpleButton>
                                    <h3>Reprezentanci</h3>
                                    <RepresentativesWrapper>
                                        {fields.map((name, index) => (
                                            <RepresentativeWrapper key={index}>
                                                <Field
                                                    name={`${name}.id`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Input {...input} id={input.name} hidden/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.name`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Imię</Label>
                                                                <Input {...input} id={input.name} placeholder="Imię"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>

                                                <Field
                                                    name={`${name}.last_name`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Nazwisko</Label>
                                                                <Input {...input} id={input.name}
                                                                       placeholder="Nazwisko"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>

                                                <Field
                                                    name={`${name}.pesel`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Pesel</Label>
                                                                <Input {...input} id={input.name} placeholder="Pesel"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.street`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Ulica</Label>
                                                                <Input {...input} id={input.name}
                                                                       placeholder="Ulica"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.apartment_number`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Numer lokalu</Label>
                                                                <Input {...input} id={input.name}
                                                                       placeholder="Numer lokalu"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.postCode`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Kod pocztowy</Label>
                                                                <Input {...input} id={input.name}
                                                                       placeholder="Kod pocztowy"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.city`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Miejscowość</Label>
                                                                <Input {...input} id={input.name}
                                                                       placeholder="Miejscowość"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.phone`}
                                                    type="tel"
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Numer telefonu</Label>
                                                                <Input {...input} id={input.name}
                                                                       placeholder="Numer telefonu"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                            </RepresentativeWrapper>
                                        ))}
                                    </RepresentativesWrapper>

                                </div>
                            )}
                        </FieldArray>
                    </RepresentativesContent>
                </StyledForm>
            )}
        />
    );
};
