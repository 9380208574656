import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {StyledFontAwesomeIcon, StyledLink} from "../../components/ListComponents";
import {faArrowRight, faEye} from "@fortawesome/free-solid-svg-icons";
import {Table} from "../../components/Table";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {Pagination} from "@material-ui/lab";
import {perPage} from "../../data/variables";

export const ClientsVehicles = ({clientId, change}) => {
    const [vehicles, setVehicles] = useState([]);
    const token = useSelector((store) => store.token);
    const readOnly = ['Partner', 'Księgowy'].includes(useSelector((store) => store.roleName));
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);


    useEffect(() => {
        axios.get(`${apiUrl}/client/${clientId}/vehicle`, {
            params: {
                page
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {vehicles: newVehicles, lastPage}}) => {
            setVehicles(newVehicles);
            setPages(lastPage);
        });
    }, [clientId, token, page, change])

    return (
        <>
            <div>
                <Table>
                    <thead>
                    <tr>
                        <th>Lp.</th>
                        <th>Marka</th>
                        <th>Model</th>
                        <th>Nr rejestracyjny</th>
                        <th>Rok produkcji</th>
                        <th>Data zakupu</th>
                        <th>Cena zakupu</th>
                        <th>Samochód z NEX1</th>
                        <th>Dostawca finansowania</th>
                        <th>Instytucja</th>
                        <th>Rodzaj finansowania</th>
                        <th>Aktualna polisa</th>
                        <th>Koniec aktualnej polisy</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {vehicles.map(({vehicle, policy}, key) => (
                        <tr>
                            <td>{(page - 1) * perPage + key + 1}</td>
                            <td>{vehicle.mark ?? '-'}</td>
                            <td>{vehicle.model ?? '-'}</td>
                            <td>{vehicle.registrationNumber}</td>
                            <td>{vehicle.yearbook ?? '-'}</td>
                            <td>{vehicle.purchased_at ?? '-'}</td>
                            <td>{vehicle.secondNet ?? '-'}</td>
                            <td>{vehicle.sourceNex1 ? 'Tak' : 'Nie'}</td>
                            <td>{vehicle.financingProvider ?? '-'}</td>
                            <td>{vehicle.institution ?? '-'}</td>
                            <td>{vehicle.financingType ?? '-'}</td>
                            <td>{vehicle.policy ?? '-'}</td>
                            <td>{policy.endedAt ?? '-'}</td>
                            <td style={{display: "flex"}}>
                                {!readOnly && <StyledLink to={`/pojazdy/${vehicle.id}`}>
                                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                </StyledLink>}
                                {readOnly && <StyledLink to={`/pojazdy/zobacz/${vehicle.id}`}>
                                    <StyledFontAwesomeIcon size="2x" icon={faEye} fixedWidth/>
                                </StyledLink>}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination count={pages} page={page} shape="rounded" size="large" onChange={(event, page) => {
                    setPage(page);
                }}/>
            </div>
        </>
    );
};
