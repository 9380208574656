import React, {useEffect, useState} from 'react';
import {Table} from "../../components/Table";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {StyledFontAwesomeIcon, StyledLink, StyledPanelContentWrapper} from "../../components/ListComponents";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {Pagination} from "@material-ui/lab";
import styled from "styled-components";
import {perPage} from "../../data/variables";

const IconsWrapper = styled.div`
    display: flex;
`;

export const WalletList = () => {
    const token = useSelector((store) => store.token);
    const [wallets, setWallets] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);

    useEffect(() => {
        axios.get(`${apiUrl}/wallets`, {
            params:{
                page,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {wallets, lastPage}}) => {
            setWallets(wallets);
            setPages(lastPage);
        });
    }, [token,page])

    return (
        <>
            <StyledPanelContentWrapper>
                <Table>
                    <thead>
                    <tr>
                        <th>Lp.</th>
                        <th>Właściciel</th>
                        <th>Stan</th>
                        <th>Niezapłacone</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {wallets.map(({id,user,value,notPaid},key) => (
                        <tr key={id}>
                            <td>{(page-1) * perPage + key + 1}</td>
                            <td style={{fontWeight: user ? "normal" : "bold"}}>{user ? user.name : 'Nex1'}</td>
                            <td>{parseFloat(value).toFixed(2)} zł</td>
                            <td>{parseFloat(notPaid).toFixed(2)} zł</td>
                            <td style={{display:"flex"}}>
                                <IconsWrapper>
                                    <StyledLink to={`/portfele/${id}`}>
                                        <StyledFontAwesomeIcon size="2x"  icon={faArrowRight} fixedWidth/>
                                    </StyledLink>
                                </IconsWrapper>
                            </td>
                        </tr>
                    ))}

                    </tbody>
                </Table>
                <Pagination count={pages} page={page} shape="rounded" size="large" onChange={(event, page) => {
                    setPage(page);
                }}/>
            </StyledPanelContentWrapper>
        </>
    );
};
