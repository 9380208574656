import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {InputSearch, Table} from "../../components/Table";
import {faArrowRight, faEye, faFileDownload} from "@fortawesome/free-solid-svg-icons";
import {StyledFontAwesomeIcon, StyledLink, StyledPanelContentWrapper} from "../../components/ListComponents";
import {useSelector} from "react-redux";
import {apiUrl} from "../api";
import {Pagination} from "@material-ui/lab";
import {perPage} from "../../data/variables";
import {downloadPackage} from "../../helpers/file";

export const ClientsList = () => {
    const token = useSelector((store) => store.token);
    const readOnly = ['Partner', 'Księgowy'].includes(useSelector((store) => store.roleName));
    const [clients, setClients] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    let timeout = 0;

    const search = (e) => {
        let searchText = e.target.value;
        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            setSearchParam(searchText);
        }, 500);
    }

    useEffect(() => {
        axios.get(`${apiUrl}/client`, {
            params: {
                page,
                search: searchParam
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {clients: newClients, lastPage}}) => {
            setClients(newClients);
            setPages(lastPage);
        });
    }, [token,page, searchParam])

    return (
        <>
            <div>
                <InputSearch onChange={(e) => {
                    search(e);
                }}/>
            </div>
            <StyledPanelContentWrapper>
                <Table>
                    <thead>
                    <tr>
                        <th>Lp.</th>
                        <th>Imię i nazwisko/Firma</th>
                        <th>Miejscowość</th>
                        <th>Ilość pojazdów</th>
                        <th>Ilość aktywnych polis</th>
                        <th>Doprowadzający</th>
                        <th>Reprezentanci</th>
                        <th>Data pozyskania</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {clients.map(({id, name, city, leader, acquiredDate, representatives,vehicleCount, policiesActiveCount},key) => (
                        <tr>
                            <td>{(page-1) * perPage + key + 1}</td>
                            <td>{name ?? '-'}</td>
                            <td>{city ?? '-'}</td>
                            <td>{vehicleCount}</td>
                            <td>{policiesActiveCount}</td>
                            <td>{leader}</td>
                            <td>{representatives}</td>
                            <td>{acquiredDate}</td>
                            <td style={{display: "flex"}}>
                                <StyledLink onClick={()=>{
                                    downloadPackage(token, 'client', id);
                                }}>
                                    <StyledFontAwesomeIcon size="2x" icon={faFileDownload} fixedWidth/>
                                </StyledLink>
                                <StyledLink to={`/klienci/zobacz/${id}`}>
                                    <StyledFontAwesomeIcon size="2x" icon={faEye} fixedWidth/>
                                </StyledLink>
                                <StyledLink style={{display: readOnly ? "none" : "block"}} to={`/klienci/${id}`}>
                                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                </StyledLink>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination count={pages} page={page} shape="rounded" size="large" onChange={(event, page) => {
                    setPage(page);
                }}/>
            </StyledPanelContentWrapper>
        </>
    );
};
