import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {
    Input, Label,
    RoundButton,
    RoundSimpleButton,
    Select,
    Switch,
    TopFormWrapper,
    VariantLabel
} from "../../components/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Field, Form} from "react-final-form";
import {Button} from "../../components/Buttons";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from 'final-form-arrays'
import axios from "axios";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Status} from "../../components/Status";
import {Files} from "../../components/Files";
import {createFormData} from "../../helpers/form";
import {downloadFile} from "../../helpers/file";
import {Comment} from "../Comment/Comment";

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  & > div {
    margin-right: 20px;
  }

  & > div:first-child label {
    display: flex;
    max-width: 130px;
    font-size: 1rem;
    padding: 10px 0;
    min-height: 45px;
    margin-bottom: 10px;
    align-items: center;
  }
`;
const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 140px 320px;
  align-items: center;
  margin-bottom: 10px;
`;

const InputWrapperWithCheckbox = styled(InputWrapper)`
  grid-template-columns: 290px 30px;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
`;

const InputWrapperWithoutLabel = styled(InputWrapper)`
  grid-template-columns: 240px;
  align-items: center;
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-right: 15px;
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  top: -20px;
  left: -5px;
`;

const StyledWrapper = styled.div`
display: flex;
flex-direction: row;
div{
margin-right: 20px;
}
&>div:first-child{
padding-right: 20px;
border-right: 1px solid black;
p,h4{
margin-top: 20px;
}
}
`;

const CustomerDataWrapper = styled.div`
  margin-bottom: 20px;
`;

export const PolicyOffer = ({toDoId, archived}) => {
    const token = useSelector((store) => store.token);
    const [foreignPolicyCost, setForeignPolicyCost] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [policyOffers, setPolicyOffers] = useState([{selected: false, insurance_company_fields: []}]);
    const [singlePremiumDisabled, setSinglePremiumDisabled] = useState([]);
    const [clientName, setClientName] = useState(null);
    const [slack, setSlack] = useState(null);
    const [status, setStatus] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [globalFiles, setGlobalFiles] = useState([]);
    const [client, setClient] = useState(null);
    const [cars, setCars] = useState(null);
    const [finances, setFinances] = useState(null);

    useEffect(() => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDos/${toDoId}/policyOffers`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({
                         data: {
                             policyOffers: newPolicyOffers,
                             isActive,
                             foreignPolicyCost,
                             clientFullName,
                             slack,
                             clientData,
                             carsData,
                             financesData,
                             policy_offer_status: newStatus,
                             statuses: newStatuses,
                             insuranceCompanies
                         }
                     }) => {
                setCompanies(insuranceCompanies);
                setIsEnabled(isActive);
                setForeignPolicyCost(foreignPolicyCost);
                setClientName(clientFullName);
                setSlack(slack);
                if (isActive) {
                    setPolicyOffers(newPolicyOffers);
                }
                setClient(clientData);
                setCars(carsData);
                setFinances(financesData);
                setStatus(newStatus);
                setStatuses(newStatuses);
            });
        }
    }, [token, toDoId]);

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (globalFiles.some(e => e.index === index)) {
            let newArr = [...globalFiles].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        } else {
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }

    const prepareFields = (fields, type, index) => {
        if (policyOffers[index] && policyOffers[index].insurance_company_fields && policyOffers[index].insurance_company_fields.insurance_checked_fields) {
            for (const property in policyOffers[index].insurance_company_fields.insurance_checked_fields) {
                policyOffers[index].insurance_company_fields.insurance_checked_fields[property] =
                    parseInt(policyOffers[index].insurance_company_fields.insurance_checked_fields[property]);
            }

            singlePremiumDisabled[index] = Object.values(policyOffers[index].insurance_company_fields['insurance_checked_fields']).indexOf(1) > -1;
        }

        return fields.map(field => {
            if (policyOffers[index] && policyOffers[index].insurance_company_fields && policyOffers[index].insurance_company_fields[type]) {
                field.value = policyOffers[index].insurance_company_fields[type][field.name];
            } else {
                field.value = '';
            }

            return field;
        });
    }

    const handleTowingFieldChange = (event, field) => {
        const index = event.target.getAttribute('data-index');

        if (policyOffers[index] === undefined || !policyOffers[index].insurance_company_fields) {
            if (policyOffers[index] === undefined) {
                policyOffers[index] = {}
            }

            policyOffers[index].insurance_company_fields = {
                'insurance_constant_fields': [],
                'insurance_towing_fields': [],
                'insurance_checked_fields': []
            };
        }
        if (!policyOffers[index].insurance_company_fields.insurance_constant_fields) {
            policyOffers[index].insurance_company_fields.insurance_constant_fields = [];
        }
        if (!policyOffers[index].insurance_company_fields.insurance_towing_fields) {
            policyOffers[index].insurance_company_fields.insurance_towing_fields = [];
        }
        if (!policyOffers[index].insurance_company_fields.insurance_checked_fields) {
            policyOffers[index].insurance_company_fields.insurance_checked_fields = [];
        }

        if (policyOffers[index].insurance_company_fields) {
            if (event.currentTarget.type === 'checkbox') {
                policyOffers[index].insurance_company_fields['insurance_towing_fields'][event.target.id] = event.currentTarget.checked ? 1 : 0;
            } else {
                policyOffers[index].insurance_company_fields['insurance_towing_fields'][event.target.id] = event.target.value;
            }
        }
    }

    const handleTowingCheckFieldChange = (event, field) => {
        const index = event.target.getAttribute('data-index');

        if (policyOffers[index] === undefined || !policyOffers[index].insurance_company_fields) {
            if (policyOffers[index] === undefined) {
                policyOffers[index] = {}
            }

            policyOffers[index].insurance_company_fields = {
                'insurance_constant_fields': [],
                'insurance_towing_fields': [],
                'insurance_checked_fields': []
            };
        }

        if (!policyOffers[index].insurance_company_fields.insurance_checked_fields) {
            policyOffers[index].insurance_company_fields.insurance_checked_fields = [];
        }

        if (policyOffers[index].insurance_company_fields) {
            policyOffers[index].insurance_company_fields['insurance_checked_fields'][field.name] = event.currentTarget.checked ? 1 : 0;
        }

        checkSinglePremiumDisabled(index);
    }

    const checkDefaultValue = (index, field) => {
        if (policyOffers[index] === undefined || !policyOffers[index].insurance_company_fields) {
            return false;
        }

        if (!policyOffers[index].insurance_company_fields.insurance_checked_fields) {
            return false;
        }

        return parseInt(policyOffers[index].insurance_company_fields['insurance_checked_fields'][field.name]) > 0;
    }

    const checkSinglePremiumDisabled = (index) => {
        if (policyOffers[index] === undefined || !policyOffers[index].insurance_company_fields) {
            return false;
        }

        if (!policyOffers[index].insurance_company_fields.insurance_checked_fields) {
            return false;
        }

        let singlePremiumDisabledClone = {...singlePremiumDisabled};
        singlePremiumDisabledClone[index] = Object.values(policyOffers[index].insurance_company_fields['insurance_checked_fields']).indexOf(1) > -1;
        setSinglePremiumDisabled(singlePremiumDisabledClone);
    }

    const changeSinglePremiumValue = (event, name, index, form, field, values) => {
        const checkboxStates = policyOffers[index].insurance_company_fields['insurance_checked_fields'] ?? [];

        if (event.currentTarget.type === "checkbox") {
            calculateSinglePremium(name, checkboxStates, index, form, values);
        }

        if (event.currentTarget.type === "number") {
            if (Object.keys(checkboxStates).length && Object.values(checkboxStates).includes(1)) {
                calculateSinglePremium(name, checkboxStates, index, form, values);
            }
        }
    }

    const calculateSinglePremium = (name, checkboxStates, index, form, values) => {
        let singlePremium = 0;

        for (const [key, value] of Object.entries(checkboxStates)) {
            if (value > 0) {
                singlePremium += parseFloat(policyOffers[index].insurance_company_fields['insurance_towing_fields'][key]);
            }
        }

        form.mutators.setValue(`${name}.singlePremium`, parseFloat(singlePremium).toFixed(2));
        values.policyOffers[index].singlePremium = parseFloat(singlePremium).toFixed(2);
    }

    return (
        <>
            <Switch checked={isEnabled} onChange={() => (setIsEnabled(!isEnabled))}/>
            <TopFormWrapper>
                <div>
                    <h2>Oferta polisy</h2>
                    {isEnabled && <RoundButton>
                        <FontAwesomeIcon icon={faPlus} color="white"/>
                    </RoundButton>}
                </div>
                <div>
                    {clientName && (
                        clientName.map((item, key) => (
                            <h2 key={key}>Dla {item}</h2>
                        ))
                    )}

                    <div className="links">
                        <h2>Slack:</h2>
                        {slack && slack['vehicleSlackLink'] && (
                            <a href={slack['vehicleSlackLink']} target="_blank">
                                <FontAwesomeIcon icon={faArrowRight} color="black" fixedWidth />
                                Samochód
                            </a>
                        )}

                        {slack && slack['policySlackLink'] && (
                            <a href={slack['policySlackLink']} target="_blank">
                                <FontAwesomeIcon icon={faArrowRight} color="black" fixedWidth />
                                Polisa
                            </a>
                        )}
                    </div>
                </div>
            </TopFormWrapper>
            {isEnabled && <>
                <Status
                    todoId={toDoId}
                    initialValues={{policy_offer_status: status}}
                    nameField="policy_offer_status"
                    statuses={statuses}
                    disabled={archived}
                />
                <Form
                    mutators={{
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value)
                        },
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                    onSubmit={values => {
                        const fd = new FormData();
                        createFormData(fd, 'policyOffers', values.policyOffers);
                        if (globalFiles.length > 0) {
                            globalFiles.forEach(({index, files}) => {
                                files.forEach((file) => {
                                    fd.append(`policyOffers[${index}][files][${file.index}][file]`, file.file);
                                })
                            });
                        }
                        axios.post(`${apiUrl}/toDos/${toDoId}/policyOffers`, fd, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {policyOffers: newPolicyOffers, isActive}}) => {
                            setIsEnabled(isActive);
                            if (isActive) {
                                setPolicyOffers(newPolicyOffers);
                            }
                            toast.success("Dane zostały zapisane.");
                        }).catch(() => {
                            toast.error("Popraw dane");
                        });
                    }}
                    initialValues={{policyOffers}}
                    render={({handleSubmit, form, values}) => (
                        <form onSubmit={handleSubmit}>
                            <StyledWrapper>
                                <CustomerDataWrapper>
                                    {client !== null && (
                                        <>
                                            <h4>
                                                DANE KLIENTA
                                            </h4>
                                            <p>
                                                NIP: {client.client_nip ?? '-'} <br/>
                                                Działalność: {client.client_activity ?? '-'} <br/>
                                                Nazwa: {client.client_name ?? '-'} <br/>
                                                KRS: {client.client_krs ?? '-'}<br/>
                                                Ulica i numer lokalu: {client.client_street ?? '-'}<br/>
                                                Kod pocztowy: {client.client_postalCode ?? '-'}<br/>
                                                Miejscowość: {client.client_city ?? '-'}<br/>
                                                Numer telefonu: {client.client_phone ?? '-'}<br/>
                                                E-mail: {client.client_email ?? '-'}<br/>
                                            </p>
                                        </>
                                    )}

                                    {cars !== null && cars.length > 0 && (
                                        cars.map((car) => (
                                            <>
                                                <h4>
                                                    DANE SAMOCHODU WARIANT {car.variantNumber}
                                                </h4>
                                                <p>
                                                    Rodzaj przedmiotu: {car.item ?? '-'} <br/>
                                                    Stan: {car.condition ?? '-'}<br/>
                                                    Marka: {car.mark ?? '-'}<br/>
                                                    Model: {car.model ?? '-'}<br/>
                                                    Rocznik: {car.yearbook ?? '-'}<br/>
                                                    Data 1 rejestracji: {car.firstRegistration ?? '-'}<br/>
                                                    Numer rejestracyjny: {car.registrationNumber ?? '-'}<br/>
                                                    VIN: {car.vin ?? '-'}<br/>
                                                    Wersja wyposażenia: {car.equipmentVersion ?? '-'}<br/>
                                                    Przebieg: {car.mileage ?? '-'}<br/>
                                                    Cena netto: {car.net ?? '-'}<br/>
                                                </p>
                                            </>
                                        ))
                                    )}

                                    {finances !== null && finances.length > 0 && (
                                        finances.map(({variantNumber, institution, financingType}) => (
                                            <>
                                                <h4>
                                                    FINANSOWANIE WARIANT {variantNumber}
                                                </h4>
                                                <p>
                                                    Instytucja: {institution ?? '-'} <br/>
                                                    Rodzaj finansowania: {financingType ?? '-'}<br/>
                                                </p>
                                            </>
                                        ))
                                    )}
                                </CustomerDataWrapper>
                                <Grid>
                                    <FieldArray name="policyOffers">
                                        {({fields}) => (
                                            <>
                                                <ButtonsWrapper>
                                                    <RoundSimpleButton
                                                        type="button"
                                                        onClick={() => fields.push({selected: false, insurance_company_fields: []})}>
                                                        <FontAwesomeIcon icon={faPlus} color="white"/>
                                                    </RoundSimpleButton>
                                                    <RoundSimpleButton
                                                        type="button"
                                                        onClick={() => fields.pop()}>
                                                        <FontAwesomeIcon icon={faMinus} color="white"/>
                                                    </RoundSimpleButton>
                                                </ButtonsWrapper>

                                                {fields.map((name, index) => (
                                                    <div>
                                                        <Field
                                                            name={`${name}.id`}
                                                            render={({input, meta}) => (
                                                                <Input {...input} id={input.name} hidden/>
                                                            )}/>
                                                        <Field
                                                            name={`${name}.variantNumber`}
                                                            type="hidden"
                                                            initialValue={index + 1}
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </>
                                                            )}
                                                        />

                                                        <Field
                                                            name={`${name}.selected`}
                                                            type="checkbox"
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <InputWrapperWithoutLabel>
                                                                        <VariantLabel>
                                                                            Wariant {index + 1}
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </VariantLabel>
                                                                    </InputWrapperWithoutLabel>
                                                                </>
                                                            )}/>

                                                        <Field
                                                            name={`${name}.tu`}
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <InputWrapper>
                                                                        <label>TU</label>
                                                                        <Select {...input} id={input.name}
                                                                                onChange={(e) => {
                                                                                    input.onChange(e);
                                                                                    const company = companies.find(item => item.id === e.target.value);
                                                                                    if (company && company?.fields_towing) {
                                                                                        form.mutators.setValue(`${name}.fields_towing`, company.fields_towing)
                                                                                    } else {
                                                                                        form.mutators.setValue(`${name}.fields_towing`, [])
                                                                                    }
                                                                                }}>
                                                                            <option value="">Firma</option>
                                                                            {companies.map(({id, name}) => (
                                                                                <option value={id}>
                                                                                    {name}
                                                                                </option>
                                                                            ))}
                                                                        </Select>
                                                                    </InputWrapper>
                                                                </>
                                                            )}/>

                                                        <Field
                                                            name={`${name}.sumInsured`}
                                                            type="number"
                                                            parse={value => (value === "" ? null : value)}
                                                            min={0}
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <InputWrapper>
                                                                        <label>Suma ubezpieczenia</label>
                                                                        <Input
                                                                            {...input}
                                                                            id={input.name}
                                                                            placeholder=""
                                                                            onChange={(e) => {
                                                                                input.onChange(e);
                                                                            }}
                                                                        />
                                                                    </InputWrapper>
                                                                </>
                                                            )}/>

                                                        {values.policyOffers[index].tu &&
                                                            <FieldArray
                                                                name={`${companies.find(c => parseInt(values.policyOffers[index].tu) === c.id).towingFields}`}>
                                                                {({fields}) => (
                                                                    <div>
                                                                        {prepareFields(companies.find(c => parseInt(values.policyOffers[index].tu) === c.id).towingFields, 'insurance_towing_fields', index).map((field, i) => (
                                                                                <div key={i}>
                                                                                    <InputWrapper>
                                                                                        <Label
                                                                                            htmlFor={field.name}>{field.title}</Label>

                                                                                        {field.field_type === 'number' &&
                                                                                            <InputWrapperWithCheckbox>
                                                                                                <Field
                                                                                                    name={`policyOffers[${index}][insurance_company_fields][insurance_towing_fields]${field.name}`}
                                                                                                    type={field.field_type}
                                                                                                    render={({
                                                                                                                 input,
                                                                                                                 meta
                                                                                                             }) => (
                                                                                                        <>
                                                                                                            <Input
                                                                                                                type="number"
                                                                                                                id={field.name}
                                                                                                                data-index={index}
                                                                                                                defaultValue={field.value ?? 0}
                                                                                                                onChange={e => handleTowingFieldChange(e, field)}
                                                                                                                onKeyUp={(e) => {
                                                                                                                    changeSinglePremiumValue(e, name, index, form, field, values);
                                                                                                                }}/>
                                                                                                        </>
                                                                                                    )}/>

                                                                                                <Field
                                                                                                    name={`policyOffers[${index}][insurance_company_fields][insurance_checked_fields]${field.name}`}
                                                                                                    type="checkbox"
                                                                                                    parse={value => (value === "" ? false : value)}
                                                                                                    render={({
                                                                                                                 input,
                                                                                                                 meta
                                                                                                             }) => (
                                                                                                        <>
                                                                                                            <Input
                                                                                                                type="checkbox"
                                                                                                                id={field.name}
                                                                                                                placeholder=""
                                                                                                                data-index={index}
                                                                                                                defaultChecked={checkDefaultValue(index, field)}
                                                                                                                onChange={e => {
                                                                                                                    handleTowingCheckFieldChange(e, field);
                                                                                                                    changeSinglePremiumValue(e, name, index, form, field, values);
                                                                                                                }}
                                                                                                            />
                                                                                                        </>
                                                                                                    )}/>
                                                                                            </InputWrapperWithCheckbox>
                                                                                        }

                                                                                        {field.field_type !== 'number' &&
                                                                                            <Field
                                                                                                name={`policyOffers[${index}][insurance_company_fields][insurance_towing_fields]${field.name}`}
                                                                                                type={field.field_type}
                                                                                                render={({input, meta}) => (
                                                                                                    <>
                                                                                                        {field.field_type === 'select' &&
                                                                                                            <Select
                                                                                                                defaultValue={field.value ?? ''}
                                                                                                                data-index={index}
                                                                                                                id={field.name}
                                                                                                                onChange={e => handleTowingFieldChange(e, field)}>
                                                                                                                <option
                                                                                                                    value="">Wybierz
                                                                                                                </option>
                                                                                                                {field.options && field.options.map((x) => {
                                                                                                                    return (
                                                                                                                        <option
                                                                                                                            key={Object.keys(x)[0]}
                                                                                                                            value={Object.keys(x)[0]}>{Object.keys(x)[0]}</option>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </Select>
                                                                                                        }

                                                                                                        {field.field_type !== 'select' &&
                                                                                                            <Input
                                                                                                                type={input.type}
                                                                                                                id={field.name}
                                                                                                                data-index={index}
                                                                                                                defaultValue={field.value ?? ''}
                                                                                                                onChange={e => handleTowingFieldChange(e, field)}/>
                                                                                                        }
                                                                                                    </>
                                                                                                )}/>
                                                                                        }
                                                                                    </InputWrapper>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </FieldArray>
                                                        }

                                                        <Field
                                                            name={`${name}.singlePremium`}
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <InputWrapper>
                                                                        <label>Składka jednorazowa</label>
                                                                        <Input
                                                                            {...input}
                                                                            id={input.name}
                                                                            placeholder=""
                                                                            disabled={singlePremiumDisabled[index]}
                                                                        />
                                                                    </InputWrapper>
                                                                </>
                                                            )}/>

                                                        <InputWrapper>
                                                            <label>Opłata za polisę obcą</label>
                                                            <Input
                                                                value={foreignPolicyCost}
                                                                placeholder=""
                                                                disabled={true}
                                                            />
                                                        </InputWrapper>

                                                        <Files name={`${name}.files`} index={index}
                                                               setFilesToForm={setFilesToForm}/>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </FieldArray>
                                </Grid>
                            </StyledWrapper>

                            {!archived && (
                                <StyledButton>Zapisz</StyledButton>
                            )}
                            <StyledButton inactive type="button" onClick={() => {
                                downloadFile(
                                    `${apiUrl}/toDos/${toDoId}/policyOffers/print`,
                                    token,
                                    `${toDoId}-oferta-polisy.pdf`
                                );
                            }}>Drukuj</StyledButton>

                        </form>
                    )}

                />
            </>}
            <Comment toDoId={toDoId} toDoTab={"policyOffer"} vehicleId={null} policyId={null} archived={false} />
        </>
    );
};
