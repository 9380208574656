import React, {useEffect, useState} from 'react';
import {Table} from "../../components/Table";
import {StyledFontAwesomeIcon} from "../../components/ListComponents";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import axios from "axios";
import {Pagination} from '@material-ui/lab';
import {apiUrl} from "../api";
import styled from "styled-components";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {Button} from "../../components/Buttons";
import {toast} from "react-toastify";

const StyledButton = styled.button`
    border: none;
    background: transparent;
    color: ${({theme: {colors: {red}}}) => red};;
    font-weight: 900;
    font-size: 1.6rem;
    top: 5px;
    right: 5px;
`;

export const ClientsTasks = ({clientId}) => {
    const [tasks, setTasks] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [modal, setModal] = useState(null);
    const [change, setChange] = useState(1);
    const token = useSelector((store) => store.token);

    useEffect(() => {
        axios.get(`${apiUrl}/personal/tasks/${clientId}`, {
            params: {
                page
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {tasks: newTasks, lastPage}}) => {
            setTasks(newTasks);
            setPages(lastPage);
        });
    }, [clientId, token, page, change])

    return (
        <>
            <div>
                <Table>
                    <thead>
                    <tr>
                        <th>Klient</th>
                        <th>Nazwa zadania</th>
                        <th>Data zadania</th>
                        <th>Data utworzenia</th>
                        <th>Data zakończenia</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {tasks.map(({id, clientName, description, date,created_at,change_status_at, status,to_do_id:toDoId},key) => (
                        <tr key={key}>
                            <td>{clientName ?? '-'}</td>
                            <td>{description}</td>
                            <td>{date}</td>
                            <td>{created_at}</td>
                            <td>{change_status_at ?? '-'}</td>
                            <td style={{display: "flex"}}>
                                {status === 0 ? (
                                    <StyledButton onClick={() => {
                                        setModal(id);
                                    }}>
                                        <StyledFontAwesomeIcon size="2x" icon={faTimes} fixedWidth/>
                                    </StyledButton>
                                ):(
                                    <StyledButton>
                                        <StyledFontAwesomeIcon size="2x" icon={faCheck} fixedWidth/>
                                    </StyledButton>
                                )}
                                {/*<StyledLink to={`/zadania/${toDoId}`}>
                                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                </StyledLink>*/}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination count={pages} page={page} shape="rounded" size="large" onChange={(event, page) => {
                    setPage(page);
                }}/>
            </div>

            {modal && <Modal closeModal={() => {
                setModal(null)
            }}>
                <P>Czy na pewno chcesz zakończyć zadanie?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.put(`${apiUrl}/personal/tasks/${modal}`,{}, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Zadanie zostało zakończone.");
                            setChange(change+1);
                        }).catch((e)=>{
                            toast.error("Nie możesz zatwierdzić zadania.");
                        }).finally(()=>{
                            setModal(null);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setModal(null)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}
        </>
    );
};
