import React, {useEffect, useState} from 'react';
import {Input, Label, RoundButton, Select, Switch, TopFormWrapper} from "../../components/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import {Button} from "../../components/Buttons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {toast} from "react-toastify";
import {Files} from "../../components/Files";
import arrayMutators from "final-form-arrays";
import {createFormData} from "../../helpers/form";
import {Status} from "../../components/Status";
import {FieldArray} from "react-final-form-arrays";
import {File} from "../../components/File";
import {SimpleFileView} from "../../components/SimpleFileView";
import {Comment} from "../Comment/Comment";

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 140px 240px;
  align-items: center;
  margin-bottom: 10px;
`;

const GapWrapper = styled(InputWrapper)`
  display: grid;
  grid-template-columns: 40px 50px;
  align-items: center;
  margin-bottom: 10px;

  label {
    font-size: 20px;
    font-weight: bold;
  }

  input[type=checkbox] {
    transform: scale(1.3);
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;

  div {
    margin-right: 20px;
  }

  & > div {
    padding-right: 20px;
  }

  p, h4 {
    margin-top: 20px;
  }
`;

const PrevPolicyWrapper = styled.div`
  border-right: 1px solid black;
`;

const StyledDivError = styled.div`
  color: red;
`;

export const PolicyTab = ({toDoId, archived}) => {
    const token = useSelector((store) => store.token);
    const [isEnabled, setIsEnabled] = useState(false);
    const [policy, setPolicy] = useState({});
    const [previousPolicy, setPreviousPolicy] = useState({});
    const [policyGap, setPolicyGap] = useState({});
    const [clientName, setClientName] = useState(null);
    const [slack, setSlack] = useState(null);
    const [signed, setSigned] = useState(null);
    const [signedGap, setSignedGap] = useState(null);
    const [paymentConfirmation, setPaymentConfirmation] = useState(null);
    const [showGap, setShowGap] = useState(false);
    const [policyZero, setPolicyZero] = useState(false);
    const [paymentConfirmationGap, setPaymentConfirmationGap] = useState(null);
    const [globalFiles, setGlobalFiles] = useState([]);
    const [globalGapFiles, setGlobalGapFiles] = useState([]);
    const [globalPhotos, setGlobalPhotos] = useState([]);
    const [globalGapPhotos, setGlobalGapPhotos] = useState([]);
    const [status, setStatus] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        axios.get(`${apiUrl}/companies/all`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({
                     data: {
                         companies: insuranceCompanies
                     }
                 }) => {
            setCompanies(insuranceCompanies);

            if (toDoId !== "dodaj") {
                axios.get(`${apiUrl}/toDos/${toDoId}/policy`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }).then(({
                             data: {
                                 policy: newPolicy,
                                 previousPolicy,
                                 gap: newPolicyGap,
                                 sumInsured,
                                 isActive,
                                 clientFullName,
                                 slack,
                                 statuses: newStatuses,
                                 policy_status: newStatus,
                             }
                         }) => {
                    setIsEnabled(isActive === 1);
                    setPolicy(newPolicy);
                    setPreviousPolicy(previousPolicy);
                    setPolicyGap(newPolicyGap);
                    setClientName(clientFullName);
                    setStatus(newStatus);
                    setStatuses(newStatuses);
                    setSlack(slack);
                    setShowGap(!!newPolicyGap.isActive);
                    setPolicyZero(!!newPolicy.policyZero);
                });
            }
        });
    }, [token, toDoId]);

    const validate = (values) => {
        const errors = {policy: {}, gap: {}}
        if (!values.policy.tuIssuer && !values.policy.policyZero) {
            errors.policy.tuIssuer = 'Uzupełnij wystawcę polisy'
        }
        if (!values.policy.insurance_company_id && !values.policy.policyZero) {
            errors.policy.insurance_company_id = 'Wybierz TU'
        }
        if (!values.policy.number && !values.policy.policyZero) {
            errors.policy.number = 'Uzupełnij numer polisy'
        }
        if (!values.policy.issuedAt && !values.policy.policyZero) {
            errors.policy.issuedAt = 'Uzupełnij datę'
        }
        if (!values.policy.insurancePremium && !values.policy.policyZero) {
            errors.policy.insurancePremium = 'Uzupełnij składkę finalną'
        }
        if (!values.policy.startedAt) {
            errors.policy.startedAt = 'Uzupełnij datę'
        }
        if (!values.policy.endedAt) {
            errors.policy.endedAt = 'Uzupełnij datę'
        }
        if (!values.policy.refinanced && !values.policy.policyZero) {
            errors.policy.refinanced = 'Uzupełnij składkę refinanowaną'
        }
        if (!values.policy.denunciation && !values.policy.policyZero) {
            errors.policy.denunciation = 'Uzupełnij wypowiedzenie'
        }

        return errors;
    }

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (!globalFiles) {
            return;
        }
        if (globalFiles.some(e => e.index === index)) {
            let newArr = [...globalFiles].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        } else {
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }

    const setGapFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (!globalGapFiles) {
            return;
        }
        if (globalGapFiles.some(e => e.index === index)) {
            let newArr = [...globalGapFiles].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalGapFiles(newArr);
        } else {
            setGlobalGapFiles(prevState => [...prevState, obj]);
        }
    }

    const setPhotosToForm = (photos, index) => {
        const obj = {
            index,
            photos
        }
        if (!globalPhotos) {
            return;
        }
        if (globalPhotos.some(e => e.index === index)) {
            let newArr = [...globalPhotos].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalPhotos(newArr);
        } else {
            setGlobalPhotos(prevState => [...prevState, obj]);
        }
    }

    const setGapPhotosToForm = (photos, index) => {
        const obj = {
            index,
            photos
        }
        if (!globalGapPhotos) {
            return;
        }
        if (globalGapPhotos.some(e => e.index === index)) {
            let newArr = [...globalGapPhotos].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalGapPhotos(newArr);
        } else {
            setGlobalGapPhotos(prevState => [...prevState, obj]);
        }
    }

    const prepareFields = (fields, type) => {
        return fields.map(field => {
            if (policy.insurance_company_fields && policy.insurance_company_fields[type]) {
                field.value = policy.insurance_company_fields[type][field.name];
            }

            return field;
        });
    }

    const appendPolicyData = (formData, values, dataKey) => {
        Object.keys(values[dataKey]).forEach((key) => {
            let value = values[dataKey][key] ?? '';
            if (value === true) {
                value = 1;
            } else if (value === false) {
                value = 0;
            }
            formData.append(`${dataKey}[${key}]`, value)
        });
    }

    const handleTowingFieldChange = (event) => {
        if (!policy.insurance_company_fields) {
            policy.insurance_company_fields = {'insurance_towing_fields': []};
        }
        if (!policy.insurance_company_fields.insurance_towing_fields) {
            policy.insurance_company_fields.insurance_towing_fields = [];
        }

        if (policy.insurance_company_fields) {
            if (event.currentTarget.type === 'checkbox') {
                policy.insurance_company_fields['insurance_towing_fields'][event.target.id] = event.currentTarget.checked ? 1 : 0;
            } else {
                policy.insurance_company_fields['insurance_towing_fields'][event.target.id] = event.target.value;
            }
        }
    }

    const findCompanyName = (prevPolicy) => {
        const company = companies.find(c => parseInt(prevPolicy.insurance_company_id) === c.id);

        return company?.name;
    }

    return (
        <>
            <Switch checked={isEnabled} onChange={() => {
                axios.post(`${apiUrl}/toDos/${toDoId}/policy/toggle`, {
                    isActive: !isEnabled,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }).then(({data: {policy: newPolicy, gap: newPolicyGap}}) => {
                    setPolicy(newPolicy);
                    setPolicyGap(newPolicyGap);
                    toast.success("Dane zostały zapisane.");
                }).catch(() => {
                    toast.error("Popraw dane");
                });
                setIsEnabled(!isEnabled)
            }}/>
            <TopFormWrapper>
                <div>
                    <h2>Polisa</h2>
                    {isEnabled === true && <RoundButton>
                        <FontAwesomeIcon icon={faPlus} color="white"/>
                    </RoundButton>}
                </div>
                <div>
                    {clientName && (
                        clientName.map((item, key) => (
                            <h2 key={key}>Dla {item}</h2>
                        ))
                    )}

                    <div className="links">
                        <h2>Slack:</h2>
                        {slack && slack['vehicleSlackLink'] && (
                            <a href={slack['vehicleSlackLink']} target="_blank">
                                <FontAwesomeIcon icon={faArrowRight} color="black" fixedWidth/>
                                Samochód
                            </a>
                        )}

                        {slack && slack['policySlackLink'] && (
                            <a href={slack['policySlackLink']} target="_blank">
                                <FontAwesomeIcon icon={faArrowRight} color="black" fixedWidth/>
                                Polisa
                            </a>
                        )}
                    </div>
                </div>
            </TopFormWrapper>

            {isEnabled === true && <>
                <Status
                    todoId={toDoId}
                    initialValues={{policy_status: status}}
                    nameField="policy_status"
                    statuses={statuses}
                    disabled={archived}
                />
                <Form
                    validate={values => validate(values)}
                    onSubmit={values => {
                        const formData = new FormData();
                        console.log(values);

                        appendPolicyData(formData, values, 'policy');
                        appendPolicyData(formData, values, 'policyGap');

                        if (signed) {
                            formData.append('policy[signed]', signed);
                        }

                        if (signedGap) {
                            formData.append('policyGap[signed]', signedGap);
                        }

                        if (paymentConfirmation) {
                            formData.append('policy[paymentConfirmation]', paymentConfirmation);
                        }

                        if (paymentConfirmationGap) {
                            formData.append('policyGap[paymentConfirmation]', paymentConfirmationGap);
                        }

                        policy.insurance_company_fields && Object.entries(policy.insurance_company_fields['insurance_towing_fields']).forEach((element) => {
                            formData.append(`policy[insuranceTowingFields][${element[0]}]`, element[1]);
                        });

                        createFormData(formData, 'policy[photos]', values.policy.photos);
                        createFormData(formData, 'policyGap[photos]', values.policyGap.photos);
                        if (globalPhotos.length > 0) {
                            globalPhotos.forEach(({photos}) => {
                                photos.forEach((photo) => {
                                    formData.append(`policy[photos][${photo.index}][file]`, photo.file);
                                })
                            });
                        }

                        if (globalGapPhotos.length > 0) {
                            globalGapPhotos.forEach(({photos}) => {
                                photos.forEach((photo) => {
                                    formData.append(`policyGap[photos][${photo.index}][file]`, photo.file);
                                })
                            });
                        }

                        createFormData(formData, 'policy[files]', values.policy.files);
                        createFormData(formData, 'policyGap[files]', values.policyGap.files);
                        if (globalFiles.length > 0) {
                            globalFiles.forEach(({files}) => {
                                files.forEach((file) => {
                                    formData.append(`policy[files][${file.index}][file]`, file.file);
                                })
                            });
                        }

                        if (globalGapFiles.length > 0) {
                            globalGapFiles.forEach(({files}) => {
                                files.forEach((file) => {
                                    formData.append(`policyGap[files][${file.index}][file]`, file.file);
                                })
                            });
                        }

                        axios.post(`${apiUrl}/toDos/${toDoId}/policy`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {policy: newPolicy, policyGap: newPolicyGap, isActive}}) => {
                            setIsEnabled(isActive);
                            setPolicy(newPolicy);
                            setPolicyGap(newPolicyGap);
                            toast.success("Dane zostały zapisane.");
                        }).catch(() => {
                            toast.error("Popraw dane");
                        });
                    }}

                    mutators={{
                        // potentially other mutators could be merged here
                        ...arrayMutators,
                        setFormAttribute: ([fieldName, fieldVal], state, {changeValue}) => {
                            changeValue(state, fieldName, () => fieldVal);
                        }
                    }}

                    initialValues={{policy: policy, policyGap: policyGap, isActive: true}}

                    render={({handleSubmit, form, values}) => (
                        <form onSubmit={handleSubmit} encType='multipart/form-data'>
                            <StyledWrapper>
                                {previousPolicy !== null && (
                                    <PrevPolicyWrapper>
                                        <h4>
                                            DANE POLISY
                                        </h4>
                                        <p>
                                            TU - Wystawca polisy: {previousPolicy.tuIssuer ?? '-'}<br/>
                                            TU: {findCompanyName(previousPolicy) ?? '-'}<br/>
                                            Polisa nr: {previousPolicy.number ?? '-'}<br/>
                                            Data wystawienia: {previousPolicy.issuedAt ?? '-'}<br/>
                                            Składka finalna: {previousPolicy.insurancePremium ?? '-'}<br/>
                                            Data rozpoczęcia polisy: {previousPolicy.startedAt ?? '-'}<br/>
                                            Data zakończenia polisy: {previousPolicy.endedAt ?? '-'}<br/>
                                            Składka refinansowana: {previousPolicy.refinanced ?? '-'}<br/>
                                            Data zapłaty: {previousPolicy.paidAt ?? '-'}<br/>
                                            Wypowiedzenie: {previousPolicy.denunciation ?? '-'}<br/>
                                            Termin płatności: {previousPolicy.payment_deadline ?? '-'}<br/>
                                            Potwierdzenie do leasingu: {previousPolicy.confirmation_leasing ?? '-'}<br/>
                                            Data przypomnienia: {previousPolicy.reminder ?? '-'}<br/><br/>
                                            Polisa podpisana: {previousPolicy.isSigned ? 'Tak' : 'Nie' ?? '-'}<br/>
                                        </p>
                                        <SimpleFileView
                                            label={'Potwierdzenie zapłaty składki/refinansowania'}
                                            fileUrl={previousPolicy.paymentConfirmation}></SimpleFileView>
                                        <SimpleFileView
                                            label={'Podpisana polisa'}
                                            fileUrl={previousPolicy.signed}></SimpleFileView>
                                    </PrevPolicyWrapper>
                                )}
                                <div>
                                    <Field
                                        name={`policy.policyZero`}
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label>Polisa zerowa</Label>
                                                    <Input {...input}
                                                           id={input.name}
                                                           placeholder=""
                                                           checked={policyZero}
                                                           onChange={(e) => {
                                                               input.onChange(e);
                                                               setPolicyZero(!policyZero);
                                                           }
                                                           }
                                                    />
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    {!policyZero && (
                                        <>
                                            <Field
                                                name={`policy.tuIssuer`}
                                                parse={value => (value === "" ? null : value)}
                                                render={({input, meta}) => (
                                                    <>
                                                        <InputWrapper>
                                                            <Label htmlFor={input.name}>TU - Wystawca polisy*</Label>
                                                            <Select {...input} id={input.name}>
                                                                <option value="">TU - Wystawca polisy</option>
                                                                <option value="VAZUVI">VAZUVI</option>
                                                                <option value="Obce">Obce</option>
                                                            </Select>
                                                            {meta.error && meta.touched &&
                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                        </InputWrapper>
                                                    </>
                                                )}/>
                                            <Field
                                                name={`policy.insurance_company_id`}
                                                render={({input, meta}) => (
                                                    <>
                                                        <InputWrapper>
                                                            <Label htmlFor={input.name}>TU*</Label>
                                                            <Select {...input} id={input.name}>
                                                                <option value="">Firma</option>
                                                                {companies.map(({id, name}) => (
                                                                    <option value={id}>
                                                                        {name}
                                                                    </option>
                                                                ))}
                                                            </Select>
                                                            {meta.error && meta.touched &&
                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                        </InputWrapper>
                                                    </>
                                                )}/>
                                            {values.policy.insurance_company_id &&
                                                <FieldArray
                                                    name={`${companies.find(c => parseInt(values.policy.insurance_company_id) === c.id).towingFields}`}>
                                                    {({fields}) => (
                                                        <div>
                                                            {prepareFields(companies.find(c => parseInt(values.policy.insurance_company_id) === c.id).towingFields, 'insurance_towing_fields').map((field, index) => (
                                                                    <div key={index}>
                                                                        <Field
                                                                            name={`${field.name}`}
                                                                            type={field.field_type}
                                                                            render={({input, meta}) => (
                                                                                <>
                                                                                    <InputWrapper>
                                                                                        <Label
                                                                                            htmlFor={field.name}>{field.title}</Label>
                                                                                        {field.field_type === 'select' &&
                                                                                            <Select
                                                                                                defaultValue={field.value ?? ''}
                                                                                                id={input.name}
                                                                                                onChange={handleTowingFieldChange}>
                                                                                                <option value="">Wybierz
                                                                                                </option>
                                                                                                {field.options && field.options.map((x) => {
                                                                                                    return (
                                                                                                        <option
                                                                                                            key={Object.keys(x)[0]}
                                                                                                            value={Object.keys(x)[0]}>{Object.keys(x)[0]}</option>
                                                                                                    )
                                                                                                })}
                                                                                            </Select>
                                                                                        }
                                                                                        {field.field_type !== 'select' &&
                                                                                            <Input type={input.type}
                                                                                                   id={input.name}
                                                                                                   defaultValue={field.value ?? ''}
                                                                                                   onChange={handleTowingFieldChange}/>
                                                                                        }
                                                                                    </InputWrapper>
                                                                                </>
                                                                            )}/>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            }
                                            <Field
                                                name='policy.number'
                                                render={({input, meta}) => (
                                                    <>
                                                        <InputWrapper>
                                                            <Label>Polisa nr*</Label>
                                                            <Input {...input} id={input.name} placeholder=""/>
                                                            {meta.error && meta.touched &&
                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                        </InputWrapper>
                                                    </>
                                                )}/>
                                            <Field
                                                name='policy.issuedAt'
                                                type="date"
                                                parse={value => (value === "" ? null : value)}
                                                render={({input, meta}) => (
                                                    <>
                                                        <InputWrapper>
                                                            <Label>Data wystawienia*</Label>
                                                            <Input {...input} id={input.name} placeholder=""/>
                                                            {meta.error && meta.touched &&
                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                        </InputWrapper>
                                                    </>
                                                )}/>
                                            <Field
                                                name='policy.insurancePremium'
                                                render={({input, meta}) => (
                                                    <>
                                                        <InputWrapper>
                                                            <Label>Składka finalna*</Label>
                                                            <Input {...input} id={input.name} placeholder=""/>
                                                            {meta.error && meta.touched &&
                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                        </InputWrapper>
                                                    </>
                                                )}/>
                                        </>
                                    )}
                                    <Field
                                        name='policy.startedAt'
                                        type="date"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label>Data rozpoczęcia polisy*</Label>
                                                    <Input {...input}
                                                           id={input.name}
                                                           placeholder=""
                                                           onChange={(e) => {
                                                               input.onChange(e);
                                                               const endDate = new Date(e.target.value);
                                                               endDate.setFullYear(endDate.getFullYear() + 1);
                                                               endDate.setDate(endDate.getDate() - 1);
                                                               const endDateString = endDate.getFullYear() + '-' + ("0" + (endDate.getMonth() + 1)).slice(-2) + '-' + ("0" + endDate.getDate()).slice(-2);
                                                               form.mutators.setFormAttribute("policy.endedAt", endDateString);
                                                           }}
                                                    />
                                                    {meta.error && meta.touched &&
                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name='policy.endedAt'
                                        type="date"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label>Data zakończenia polisy*</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                    {meta.error && meta.touched &&
                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    {!policyZero && (
                                        <>
                                            <Field
                                                name='policy.refinanced'
                                                render={({input, meta}) => (
                                                    <>
                                                        <InputWrapper>
                                                            <Label>Składka refinansowana*</Label>
                                                            <Select {...input} id={input.name}>
                                                                <option value="">Składka refinansowana</option>
                                                                <option value="Tak">Tak</option>
                                                                <option value="Nie">Nie</option>
                                                            </Select>
                                                            {meta.error && meta.touched &&
                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                        </InputWrapper>
                                                    </>
                                                )}/>

                                            <Field
                                                name='policy.paidAt'
                                                type="date"
                                                parse={value => (value === "" ? null : value)}
                                                render={({input, meta}) => (
                                                    <>
                                                        <InputWrapper>
                                                            <Label>Data zapłaty</Label>
                                                            <Input {...input} id={input.name} placeholder=""/>
                                                            {meta.error && meta.touched &&
                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                        </InputWrapper>
                                                    </>
                                                )}/>

                                            <Field
                                                name='policy.denunciation'
                                                render={({input, meta}) => (
                                                    <>
                                                        <InputWrapper>
                                                            <Label>Wypowiedzenie*</Label>
                                                            <Select {...input} id={input.name}>
                                                                <option value="">Wypowiedzenie</option>
                                                                <option value="Tak">Tak</option>
                                                                <option value="Nie">Nie</option>
                                                                <option value="Nie dotyczy">Nie dotyczy</option>
                                                            </Select>
                                                            {meta.error && meta.touched &&
                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                        </InputWrapper>
                                                    </>
                                                )}/>

                                            <Field
                                                name='policy.payment_deadline'
                                                type="date"
                                                parse={value => (value === "" ? null : value)}
                                                render={({input}) => (
                                                    <InputWrapper>
                                                        <Label>Termin płatności</Label>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </InputWrapper>
                                                )}
                                            />
                                            <Field
                                                name='policy.confirmation_leasing'
                                                type="date"
                                                parse={value => (value === "" ? null : value)}
                                                render={({input}) => (
                                                    <InputWrapper>
                                                        <Label>Potwierdzenie do leasingu</Label>
                                                        <Input {...input} id={input.name} placeholder=""
                                                               onChange={(e) => {
                                                                   input.onChange(e);
                                                                   const tempDate = e.target.value;
                                                                   const year = new Date(tempDate).getFullYear();
                                                                   const month = new Date(tempDate).getMonth();
                                                                   const day = new Date(tempDate).getDate();
                                                                   const d = new Date(year + 1, month, day);
                                                                   const datestring = d.getFullYear() + '-' + ("0" + (d.getMonth() + 1)).slice(-2) + '-' + ("0" + d.getDate()).slice(-2);
                                                                   form.mutators.setFormAttribute("policy.reminder", datestring)
                                                               }
                                                               }
                                                        />
                                                    </InputWrapper>
                                                )}
                                            />
                                        </>
                                    )}
                                    <Field
                                        name='policy.reminder'
                                        type="date"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label>Data przypomnienia</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputWrapper>
                                            </>
                                        )}
                                    />
                                    {!policyZero && (
                                        <>
                                            <Field
                                                name='policy.slackLink'
                                                render={({input, meta}) => (
                                                    <>
                                                        <InputWrapper>
                                                            <Label>Slack link</Label>
                                                            <Input {...input} id={input.name} placeholder=""/>
                                                        </InputWrapper>
                                                    </>
                                                )}/>

                                            <Field
                                                name='policy.isSigned'
                                                type="checkbox"
                                                render={({input, meta}) => (
                                                    <>
                                                        <InputWrapper>
                                                            <Label>Polisa podpisana</Label>
                                                            <Input {...input} id={input.name} placeholder=""/>
                                                        </InputWrapper>
                                                    </>
                                                )}/>

                                            <Field
                                                name='policy.requiredImages'
                                                type="checkbox"
                                                render={({input, meta}) => (
                                                    <>
                                                        <InputWrapper>
                                                            <Label>Zdjęcia wymagane</Label>
                                                            <Input {...input} id={input.name} placeholder=""/>
                                                        </InputWrapper>
                                                    </>
                                                )}/>

                                            <File
                                                name="policy.paymentConfirmation_path"
                                                label="Potwierdzenie zapłaty składki/refinansowania"
                                                onFileSelect={(file) => setPaymentConfirmation(file)}
                                                initialValue={values.policy.paymentConfirmation}
                                            />

                                            <File
                                                name="policy.signed_path"
                                                label="Podpisana polisa"
                                                onFileSelect={(file) => setSigned(file)}
                                                initialValue={values.policy.signed}
                                            />

                                            <Files name={`policy.photos`} index={0} setFilesToForm={setPhotosToForm}
                                                   label={`Zdjęcia`}/>
                                            <Files name={`policy.files`} index={0} setFilesToForm={setFilesToForm}/>
                                        </>
                                    )}
                                </div>
                                {!policyZero && (
                                    <div>
                                        <Field
                                            name={`policyGap.isActive`}
                                            type="checkbox"
                                            render={({input, meta}) => (
                                                <>
                                                    <GapWrapper>
                                                        <Label>GAP</Label>
                                                        <Input {...input}
                                                               id={input.name}
                                                               placeholder=""
                                                               checked={showGap}
                                                               onChange={(e) => {
                                                                   input.onChange(e);
                                                                   setShowGap(!showGap);
                                                               }
                                                               }
                                                        />
                                                    </GapWrapper>
                                                </>
                                            )}/>

                                        {showGap && (
                                            <>
                                                <Field
                                                    name={`policyGap.tuIssuer`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>TU - Wystawca polisy</Label>
                                                                <Select {...input} id={input.name}>
                                                                    <option value="">TU - Wystawca polisy</option>
                                                                    <option value="VAZUVI">VAZUVI</option>
                                                                    <option value="Obce">Obce</option>
                                                                </Select>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`policyGap.tuId`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <label>TU</label>
                                                                <Select {...input} id={input.name} onChange={(e) => {
                                                                    input.onChange(e);
                                                                }}>
                                                                    <option value="">Firma</option>
                                                                    {companies.map(({id, name}) => (
                                                                        <option value={id}>
                                                                            {name}
                                                                        </option>
                                                                    ))}
                                                                </Select>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`policyGap.financingPeriod`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    min={0}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <label>Okres finasowania w latach</label>
                                                                <Input
                                                                    {...input}
                                                                    id={input.name}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        input.onChange(e);
                                                                        if (values.policyGap.startDate) {
                                                                            const financingPeriod = e.target.value ? parseInt(e.target.value) : 1;
                                                                            const endDate = new Date(values.policyGap.startDate);
                                                                            endDate.setFullYear(endDate.getFullYear() + financingPeriod);
                                                                            endDate.setDate(endDate.getDate() - 1);
                                                                            const endDateString = endDate.getFullYear() + '-' + ("0" + (endDate.getMonth() + 1)).slice(-2) + '-' + ("0" + endDate.getDate()).slice(-2);
                                                                            form.mutators.setFormAttribute("policyGap.endDate", endDateString);
                                                                        }
                                                                    }}
                                                                />
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`policyGap.sumInsured`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    min={0}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <label>Suma ubezpieczenia</label>
                                                                <Input
                                                                    {...input}
                                                                    id={input.name}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        input.onChange(e);
                                                                    }}
                                                                />
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`policyGap.compensationLimit`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    min={0}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <label>Limit odszkodowania</label>
                                                                <Input
                                                                    {...input}
                                                                    id={input.name}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        input.onChange(e);
                                                                    }}
                                                                />
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`policyGap.totalPremium`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    min={0}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <label>Składka całkowita</label>
                                                                <Input
                                                                    {...input}
                                                                    id={input.name}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        input.onChange(e);
                                                                    }}
                                                                />
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`policyGap.number`}
                                                    type="text"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <label>Polisa numer</label>
                                                                <Input
                                                                    {...input}
                                                                    id={input.name}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        input.onChange(e);
                                                                    }}
                                                                />
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`policyGap.type`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Typ</Label>
                                                                <Select {...input} id={input.name}>
                                                                    <option value="">Typ</option>
                                                                    <option value="oneTime">Składka jednorazowa</option>
                                                                    <option value="installment">Składka ratalna</option>
                                                                </Select>
                                                                {meta.error && meta.touched &&
                                                                    <StyledDivError>{meta.error}</StyledDivError>}
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`policyGap.installmentValue`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    min={0}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <label>Składka ratalna</label>
                                                                <Input
                                                                    {...input}
                                                                    id={input.name}
                                                                    placeholder=""
                                                                    disabled={(values.gap_type !== 'installment')}
                                                                    onChange={(e) => {
                                                                        input.onChange(e);
                                                                    }}
                                                                />
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`policyGap.startDate`}
                                                    type="date"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <label>Data rozpoczęcia</label>
                                                                <Input
                                                                    {...input}
                                                                    id={input.name}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        input.onChange(e);
                                                                        const financingPeriod = values.policyGap.financingPeriod ? parseInt(values.policyGap.financingPeriod) : 1;
                                                                        const endDate = new Date(e.target.value);
                                                                        endDate.setFullYear(endDate.getFullYear() + financingPeriod);
                                                                        endDate.setDate(endDate.getDate() - 1);
                                                                        const endDateString = endDate.getFullYear() + '-' + ("0" + (endDate.getMonth() + 1)).slice(-2) + '-' + ("0" + endDate.getDate()).slice(-2);
                                                                        form.mutators.setFormAttribute("policyGap.endDate", endDateString);
                                                                    }}
                                                                />
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`policyGap.endDate`}
                                                    type="date"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <label>Data zakończenia</label>
                                                                <Input
                                                                    {...input}
                                                                    id={input.name}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        input.onChange(e);
                                                                    }}
                                                                />
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`policyGap.refinancingPremium`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Składka
                                                                    refinansowania</Label>
                                                                <Select {...input} id={input.name}>
                                                                    <option value="">Składka refinansowania</option>
                                                                    <option value="inCarPrice">W cenie pojazdu</option>
                                                                    <option value="inFee">Zawarta w prowizji</option>
                                                                    <option value="externalInstallments">Raty zewnętrzne
                                                                    </option>
                                                                    <option value="Nie">Nie</option>
                                                                </Select>
                                                                {meta.error && meta.touched &&
                                                                    <StyledDivError>{meta.error}</StyledDivError>}
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`policyGap.dateOfPayment`}
                                                    type="date"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <label>Termin płatności</label>
                                                                <Input
                                                                    {...input}
                                                                    id={input.name}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        input.onChange(e);
                                                                    }}
                                                                />
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`policyGap.reminder`}
                                                    type="date"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <label>Przypomnienie</label>
                                                                <Input
                                                                    {...input}
                                                                    id={input.name}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        input.onChange(e);
                                                                    }}
                                                                />
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`policyGap.dateOfPayment2`}
                                                    type="date"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <label>Data zapłaty</label>
                                                                <Input
                                                                    {...input}
                                                                    id={input.name}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        input.onChange(e);
                                                                    }}
                                                                />
                                                            </InputWrapper>
                                                        </>
                                                    )}/>

                                                <File
                                                    name="policyGap.paymentConfirmation_path"
                                                    label="Potwierdzenie zapłaty składki/refinansowania"
                                                    onFileSelect={(file) => setPaymentConfirmationGap(file)}
                                                    initialValue={values.policyGap.paymentConfirmation}
                                                />

                                                <File
                                                    name="policyGap.signed_path"
                                                    label="Podpisana polisa"
                                                    onFileSelect={(file) => setSignedGap(file)}
                                                    initialValue={values.policyGap.signed}
                                                />

                                                <Files name={`policyGap.photos`} index={0}
                                                       setFilesToForm={setGapPhotosToForm}
                                                       label={`Zdjęcia`}/>
                                                <Files name={`policyGap.files`} index={0}
                                                       setFilesToForm={setGapFilesToForm}/>
                                            </>
                                        )}
                                    </div>
                                )}
                            </StyledWrapper>
                            {!archived && (
                                <Button>Zapisz</Button>
                            )}
                        </form>
                    )}
                />
            </>}
            <Comment toDoId={toDoId} toDoTab={"policy"} vehicleId={null} policyId={null} archived={false} />
        </>
    );
};
