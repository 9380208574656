import React, {useState} from 'react';
import styled from "styled-components";
import {Button} from "../../components/Buttons";
import {ToDoListTable} from "./ToDoListTable";
import PanelContentWrapper from "../../components/PanelContentWrapper";

const TabWrapper = styled.div`
display: grid;
grid-template-columns: repeat(4, auto);
grid-gap: 15px;
margin-bottom: 20px;
${Button}{
position: relative;
display: flex;
align-items: center;
justify-content: center;
&:not(:last-child)::after{
content: '';
position: absolute;
right: -15px;
width: 15px;
height: 1px;
background-color: #827E7E;
}
}
`;

const StyledPanelContentWrapper = styled(PanelContentWrapper)`
  padding: 30px 30px;
`;

export const ToDoList = ({archived}) => {
    const [activeTab, setActiveTab] = useState(1);

    return (
        <>
            <StyledPanelContentWrapper>
                <TabWrapper>
                    <Button type="button" inactive={activeTab !== 1} onClick={() => {
                        setActiveTab(1)
                    }}>Bez kategorii</Button>
                    <Button type="button" inactive={activeTab !== 2} onClick={() => {
                        setActiveTab(2)
                    }}>Samochód</Button>
                    <Button type="button" inactive={activeTab !== 3} onClick={() => {
                        setActiveTab(3)
                    }}>Finansowanie</Button>
                    <Button type="button" inactive={activeTab !== 4} onClick={() => {
                        setActiveTab(4)
                    }}>Polisy</Button>
                </TabWrapper>

                {activeTab === 1 && <>
                    <ToDoListTable archived={archived} category={'none'} />
                </>}

                {activeTab === 2 && <>
                    <ToDoListTable archived={archived} category={'vehicle'} />
                </>}

                {activeTab === 3 && <>
                    <ToDoListTable archived={archived} category={'finance'} />
                </>}

                {activeTab === 4 && <>
                    <ToDoListTable archived={archived} category={'policy'} />
                </>}
            </StyledPanelContentWrapper>
        </>
    );
};
